import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
  loading=true;
  constructor() { }

  ngOnInit() {
    setTimeout(() => {
      this.loading=false
    }, 300);
  }
}
