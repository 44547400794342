<!-- <app-navbar [menuType]="2"></app-navbar> -->
<div class="page-container">
  <div class="header-bar">
    <div class="header-cont">
      <span class="header-text">
        <a routerLink="/">Anasayfa</a>  > <span class="active-text">Hizmet Şartlarımız</span>
      </span>
    </div>
  </div>
  <!-- <app-loading *ngIf="loading"></app-loading> -->
  <div class="body-container" *ngIf="!loading">
    <div class="row">
      <div class="pad-0 col-md-6">
        <div class="mes-cnt">
          <p class="title">Hizmet Şartlarımız</p>
          <p class="sub-title">
            Kangurus, çocuklar için güvenli bir sosyal medya platformu sunmak
            amacıyla geliştirilmiştir. Kullanıcı olarak, uygulamayı yalnızca
            yasal amaçlar için kullanabileceğinizi kabul edersiniz. Ayrıca,
            aşağıdaki sorumlulukları ve kısıtlamaları kabul edersiniz:
          </p>
            <div class="sub-mes">
            <p class="mes clr-prple">Kullanım Koşulları</p>
            <p class="mes">
              a. Uygulamayı sadece yasal amaçlar için kullanabilirsiniz. Yasalara uygun olarak hareket etmekle yükümlüsünüz.
            </p>
            <p class="mes">
              b. Uygulamayı kullanarak herhangi bir yasadışı faaliyeti gerçekleştiremezsiniz.
            </p>
            <p class="mes">
              c. Uygulamanın içeriğine zarar veremez, değiştiremez veya müdahale edemezsiniz.
            </p>
            <p class="mes">
              d. Uygulamayı kullanarak diğer kullanıcıların gizliliğini veya güvenliğini tehlikeye atamazsınız.
            </p>
            <p class="mes clr-prple">Hesap Oluşturma ve Güvenliği</p>
            <p class="mes">
              a. Uygulamayı kullanmak için hesap oluşturmanız gerekir.
            </p>
            <p class="mes">
              b. Hesabınızın kullanıcı adı ve şifresini güvenli bir şekilde
              saklamakla yükümlüsünüz.
            </p>
            <p class="mes">
              c. Hesabınızın güvenliği konusunda sorularınız varsa, lütfen
              bizimle iletişime geçin.
            </p>
            <p class="mes">
              d. Hesabınızın kullanımından yalnızca siz sorumlu olursunuz.
            </p>
            <p class="mes clr-prple">Kullanıcı İçeriği</p>
            <p class="mes">
              a. Kullanıcı hesabınızla gönderilen içeriklerden yalnızca siz
              sorumlu olursunuz.
            </p>
            <p class="mes">
              b. İçeriklerinizin telif haklarına saygı göstermeniz gerektiğini
              kabul edersiniz.
            </p>
            <p class="mes">
              c. İçeriklerinizin uygunsuz veya saldırgan olmaması gerektiğini
              kabul edersiniz.
            </p>
            <p class="mes clr-prple">Değişiklikler</p>
            <p class="mes">
              a. Hizmet Şartlarını zaman zaman güncelleme hakkımız saklıdır.
            </p>
            <p class="mes">
              b. Önemli değişiklikler olduğunda, uygulama üzerinden size
              bildirimde bulunacağız.
            </p>
            <p class="mes">
              c. Güncellenmiş hizmet şartlarını kabul etmek için, uygulamayı
              kullanmaya devam etmeniz gerekmektedir.
            </p>
            <p class="mes">
              Kangurus’u kötüye kullandığınız tespit edilirse, hukuki işlemler
              başlatma hakkımız saklıdır. Ayrıca, uygulama kullanımınız sırasında
              kişisel verilerinizin toplanması konusunda lütfen gizlilik
              politikamızı inceleyin.
            </p>
          </div>
        </div>
      </div>
      <div class="pad-0 col-md-6">
        <!-- <img class="hero-img" src="./assets/img/hero-img.png" /> -->
      </div>
    </div>
  </div>
</div>
<!-- <app-footer></app-footer> -->
