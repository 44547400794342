import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { PrivacyPolicyComponent } from './modules/contracts/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'event-guide',
    loadChildren: () => import('./modules/event-guide/event-guide.module').then(m => m.EventGuideModule),
  },
  {
    path: 'event-guide/:operationType',
    loadChildren: () => import('./modules/event-guide/event-guide.module').then(m => m.EventGuideModule),
  },
  {
    path: 'event-guide/event-detail/:id',
    loadChildren: () => import('./modules/event-guide/event-guide.module').then(m => m.EventGuideModule),
  },
  {
    path: 'event-guide/place-detail/:id',
    loadChildren: () => import('./modules/event-guide/event-guide.module').then(m => m.EventGuideModule),
  },
  {
    path: 'about-us',
    loadChildren: () => import('./modules/about-us/about-us.module').then(m => m.AboutUsModule),
  },
  {
    path: 'faq',
    loadChildren: () => import('./modules/faq/faq.module').then(m => m.FaqModule),
  },
  {
    path: 'be-partner',
    loadChildren: () => import('./modules/be-partner/be-partner.module').then(m => m.BePartnerModule),
  },
  {
    path: 'be-partner/partner-form',
    loadChildren: () => import('./modules/be-partner/be-partner.module').then(m => m.BePartnerModule),
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./modules/contact-us/contact-us.module').then(m => m.ContactUsModule),
  },

  {
    path: 'mobile-menu',
    loadChildren: () => import('./modules/nav-bar/navbar.module').then(m => m.NavbarModule),
  },
  {
    path: 'contracts/:operationType',
    loadChildren: () => import('./modules/contracts/contracts.module').then(m => m.ContractsModule),
  },
  { path: 'terms-of-service', component: TermsOfServiceComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  // { path: 'mobile-menu', component: MobileMenuComponent },
  { path: 'not-found', loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule) }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always',
    scrollPositionRestoration: 'top'
  })],

  
  exports: [RouterModule]
})
export class AppRoutingModule { }
