<!-- <app-navbar [menuType]="2"></app-navbar> -->
<!-- <div class="page-container"> -->
<!-- <div class="header-bar">
    <div class="header-cont">
      <span class="header-text">
        <a routerLink="/">Anasayfa</a>  > <strong>Gizlilik Politikamız</strong>
      </span>
    </div>
  </div> -->
<!-- <app-loading *ngIf="loading"></app-loading> -->
<!-- <div class="body-container" *ngIf="!loading"> -->
<ng-container *transloco="let t; read: 'contracts.PRIVACY_POLCY'">
  <div class="mes-cnt" *ngIf="!loading">
    <p class="title">
      {{ t("TITLE") }}
    </p>
    <p class="sub-title">
      {{ t("TITLE_MES") }}
    </p>

    <div class="sub-mes">
      <p class="mes clr-blck">
      {{ t("SUB_TITLE_1") }}
      </p>
      <p class="mes">
      {{ t("MES_1") }}
      </p>

      <p class="mes">
        {{ t("NAME_SURNAME") }}<br/>
        {{ t("EMAIL") }}<br/>
        {{ t("PHONE_NUMBER") }}<br/>
        {{ t("BIRTH_DATE") }}<br/>
        {{ t("PROFILE_PHOTO") }}<br/>
        {{ t("USER_NAME_PASS") }}
      </p>
      <p class="mes">
        {{ t("MES_2") }}
      </p>
      <p class="mes">
        {{ t("MES_3") }}<br/>
        {{ t("MES_4") }}<br/>
        {{ t("MES_5") }}<br/>
        {{ t("MES_6") }}<br/>
      </p>
      <p class="mes clr-blck">
        {{ t("SUB_TITLE_2") }}
      </p>
      <p class="mes">
        {{ t("MES_7") }}<br/>
        {{ t("MES_8") }}<br/>
        {{ t("MES_9") }}<br/>
        {{ t("MES_10") }}<br/>
        {{ t("MES_11") }}<br/>
      </p>
      <p class="mes">
        {{ t("MES_12") }}<br/>
      </p>
      <p class="mes clr-blck">
        {{ t("SUB_TITLE_3") }}
      </p>
      <p class="mes">
        {{ t("MES_13") }}
      </p>
      <p class="mes clr-prple">
        {{ t("SUB_TITLE_4") }}
      </p>
      <p class="mes">
        {{ t("MES_15") }}
      </p>
    </div>
    <!-- </div> -->
  </div>
  <!-- </div> -->
  <!-- <app-footer></app-footer> -->
</ng-container>
