import { env } from './.env';
import { EnvironmentModel } from './enviroment.model';

export const environment: EnvironmentModel = {
  production: true,
  apiUrl: '/api',
  defaultLanguage: env.defaultLanguage,
  availableLangs: env.availableLangs,
  appName: env.appName,
  appCompany: env.appCompany,
  appVersion: 'v1.0.0'
};
